import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { suggestionStore } from '@/services/Suggestion/store'
import { parseDate } from '@/utilities/Date/parse'

export const methods = {
  determineRows: async function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const suggestionID = _get(entry, 'suggestionID', 0)
      const suggestion = _get(entry, 'suggestionText', '')
      const createdBy = _get(entry, 'identityInformation.name', '')
      const createdDate = parseDate(_get(entry, ['createdDate'], ''), 'LL')

      return {
        suggestion: suggestion,
        createdBy: createdBy,
        createdDate: createdDate,
        delete: {
          component: Button,
          props: {
            onClick: async () => {
              await this.confirmDeleteSuggestion(suggestionID, suggestion)
            },
            text: 'Delete'
          }
        },
        askquestion: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToNewConversation(entry)
            },
            text: 'Ask a Question'
          }
        },
        detail: {
          component: Button,
          props: {
            onClick: () => {
              this.redirectToDetail(suggestionID)
            },
            text: 'Detail'
          }
        }
      }
    })

    this.rows = rows
  },

  redirectToDetail(suggestionID) {
    if (suggestionID) {
      this.$router
        .push({
          path: `/suggestionTabs/${suggestionID}/suggestionDetail#tab`,
          id: suggestionID
        })
        .catch(() => {})
    }
  },

  redirectToNewConversation(suggestion) {
    if (suggestion) {
      const suggestionID = _get(suggestion, 'suggestionID', 0)
      const ownerID = _get(suggestion, 'ownerID', 0)
      const createdBy = _get(suggestion, 'identityInformation.name', '')

      this.$router.push({
        name: 'newconversation',
        params: {
          suggestionid: suggestionID,
          suggestionownerid: ownerID,
          suggestionlabel: `Add Homeowner - ${createdBy}`
        }
      })
    }
  },

  async confirmDeleteSuggestion(suggestionID, suggestion) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Suggestion',
      message: `Are you sure you want to <b>delete</b> this suggestion: "${suggestion}"?`,
      confirmText: 'Delete Suggestion',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteSuggestion(suggestionID)
    })
  },

  async deleteSuggestion(suggestionID) {
    if (suggestionID) {
      await suggestionStore.dispatch('deleteSuggestion', {
        suggestionID: suggestionID,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    }
  },

  async loadSuggestions() {
    if (this.isDebug == true) console.debug('in loadSuggestions()...')

    await suggestionStore
      .dispatch('getSuggestionList', {
        hoaID: this.hoaId,
        ellipsisCharacterCount: 100
      })
      .then(async ({ list }) => {
        await this.determineRows(list)
      })
  },

  async reload() {
    this.loading = true

    this.rows = []
    await this.loadSuggestions()

    this.loading = false
  }
}
