<template>
  <PageContentLayoutOnly role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'Suggestions',
  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  data,

  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { suggestions: { title: 'Suggestions' } }
    }
  }
}
</script>

<style></style>
